@import '../../style/settings/vars'

.table
  composes: responsive w-100 from __utilities
  :global thead
    th
      background-color: transparent !important
      padding-top: .5rem !important
      padding-bottom: .5rem !important
      color: gray_base !important
      border: 0 !important

.table-row
  composes: mb2 from __utilities
  td
    background-color: gray_dark !important
    border-bottom: 0 !important
    &:first-child
      // border-top-left-radius: 10px
      // border-bottom-left-radius: 10px
      border-left: 4px solid primary
    &:last-child
      // border-top-right-radius: 10px
      // border-bottom-right-radius: 10px
