@import '../settings/vars'

input
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea
  border-radius: 3px
  // font-family: font_base
  // border: .1rem solid gray_ultralight
  // background-color: gray_ultralight
  // padding: 1rem
  // font-size: 1rem
  // font-weight: 300
  width: 100%
  &:focus
    outline: 0
    border: .1rem solid rgba(primary, 1)
  &:disabled
    color: rgba(gray_base, .8)
    cursor: no-drop;
  &::placeholder
    color: #666

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea
  &.form-control
    background: white
    color: gray_base

textarea
  // font-weight: 300
  // font-family: font_base
  max-width: 100%
  &::placeholder
    color: #666 !important

select
  border: 0
  text-indent: .4rem
  color: gray_dark
  &:focus
    outline: 0
    border: .1rem solid rgba(primary, .6)

label
  font-size: .9rem
  font-weight: 500
