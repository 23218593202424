@import '../../style/settings/vars'

.wrapper
  composes: mv4 responsive from __utilities
  color: white

.select
  composes: w-100 mw5 pv3 from __utilities

.otherwhise
  composes: mh3 dib from __utilities
  color: gray_base