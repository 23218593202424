@import '../../style/settings/vars'

.table
  composes: absolute from __utilities
  height: 100px
  width: 100px
  height: 7vw
  width: 7vw
  border: 1px solid gray_dark
  background: black
  transform: translate(-50%, -50%)

.table-small
  composes: table
  background-color: gray_darker
  border: 1px solid black
  height: 80px
  width: 80px
  height: 6vw
  width: 6vw

.number
  composes: absolute db fw7 f4 from __utilities
  top: 10px
  left: 10px
  line-height: 1

.number-used
  composes: number
  color: red

.number-busy
  composes: number
  color: yellow

.status
  composes: absolute db f7 from __utilities
  bottom: 10px
  left: 10px
  line-height: 1.2

.status-used
  color: red

.status-busy
  color: yellow

.icons
  composes: flex flex-column items-center justify-center absolute br4 from __utilities
  top: 10px
  right: 10px

.status-icon
  composes: flex mb2 items-center justify-center br4 from __utilities
  color: white
  width: 25px
  height: 25px
  svg
    height 70%

.status-icon
  composes: status-icon
  svg
    width: 40px
    height: 40px

.reserved-icon
  composes: status-icon

.status-label
  composes: db from __utilities
  color: gray_base

.chair
  composes: absolute o-60 from __utilities
  background: white
  border: 1px solid gray_base

.chair-vertical
  composes: chair
  width: 50px
  height 45px
  &:after
    content: ''
    display: block
    position: absolute
    left: 0
    right: 0
    height: 1px
    background-color: gray_base

.chair-up
  composes: chair-vertical
  bottom: 100%
  left: 50%
  transform: translateX(-50%)
  &:after
    top: 5px

.chair-down
  composes: chair-vertical
  top: 100%
  left: 50%
  transform: translateX(-50%)
  &:after
    bottom: 5px

.chair-horizontal
  composes: chair
  width: 45px
  height 50px
  &:after
    content: ''
    display: block
    position: absolute
    top: 0
    bottom: 0
    width: 1px
    background-color: gray_base

.chair-left
  composes: chair-horizontal
  top: 50%
  right: 100%
  transform: translateY(-50%)
  &:after
    left: 5px

.chair-right
  composes: chair-horizontal
  top: 50%
  left: 100%
  transform: translateY(-50%)
  &:after
    right: 5px