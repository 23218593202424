@import '../../style/settings/vars'

.drawer
  transform: none !important
  :global .ant-drawer-body
    padding: 0 !important
    :global .ant-layout
      background-color: transparent !important
  
  :global .ant-scroll-number-only > p
    line-height: 20px

  :global .ant-menu-item
    height: auto !important
    min-height: 40px !important
    small
      opacity .5

  :global .ant-layout-sider,
  :global .ant-layout 
    background-color: transparent !important

  :global
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:active
      background-color: black
      color: white

.main-wrapper
  min-height: 100% !important
  min-height: 100vh !important
  padding: 30px
  color: white