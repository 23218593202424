@import '../../style/settings/vars'

.drawer
  transform: none !important
  :global .ant-drawer-body
    padding: 0 !important
    :global .ant-layout
      background-color: transparent !important
  
  :global .ant-scroll-number-only > p
    line-height: 20px

  :global .ant-menu-item
    height: auto !important
    min-height: 40px !important
    small
      opacity .5

  :global .ant-layout-sider,
  :global .ant-layout 
    background-color: transparent !important

  :global
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:active
      background-color: black
      color: white

.main-wrapper
  min-height: 100% !important
  min-height: 100vh !important

.content
  :global .ant-tabs-nav-container
    padding-left: 30px
  :global .ant-tabs-content
    padding-left: 30px

.menu-title
  composes: ph3 mb2 from __utilities

.item
  composes: mr3 mb3 from __utilities

.order-items-wrapper
  composes: flex flex-wrap from __utilities

.tab-title
  composes: pr2 db from __utilities

.button-add
  composes: f5 db tc mv3 w-100 from __utilities
  color: gray_base
  svg 
    margin-right: 5px
    color: primary

.footer
  composes: absolute left-0 bottom-0 right-0 h3 ph3 flex justify-between items-center from __utilities
  background: gray_darker
  border-top: 1px solid black

.sider
  border-right: 1px solid black
  :global .ant-menu
    border-right: 0 !important
    :global .ant-menu-item
      pointer-events: none

.button
  min-width: 250px

.button-delete
  composes: underline from __utilities
  color: gray_base

.menu-plate
  composes: db f6 truncate overflow-hidden from __utilities
  background: rgba(gray_dark, 0.5)
  color: gray_base
  border-bottom: 1px solid gray_darker 
  margin-left: -24px
  margin-right: -24px
  padding-left: 24px
  padding-right: 24px
  height: auto;
  line-height: 36px

.tab-badge
  :global .ant-badge-count
    padding: 0 3px !important
    height: 16px !important
    width: 16px !important
    min-width: 16px !important
    line-height: 16px !important
    background-color: gray_base !important
  :global .ant-scroll-number-only > p
    line-height: 16px !important
    font-size: 0.8em

  
.children-buttons
  composes: mb3 from __utilities
  :global .ant-btn
    margin-right: 10px !important
    margin-top: 5px !important
    margin-bottom: 5px !important

.side-total
  composes: f5 pv3 from __utilities
  border-top: 1px solid black
  color: gray_light
  color: white
  padding-left: 1.6rem
  padding-right: 1.6rem

.header
  composes: ph4 f4 bb flex items-center from __utilities
  border-color: black
  color: white
  height 61px

.return-to-orders-wrapper
  composes: absolute bottom-0 w-100 left-0 ph3 pv3 from __utilities