@import '../../style/settings/vars'

.wrapper
  composes: pa4 from __utilities

.title
  composes: mb3 f5 from __utilities
  color: gray_base

.radio-item
  font-size: 1rem !important
  padding: 1rem !important
  display: block !important
  border-bottom: 1px solid black

.number-radio
  :global .ant-radio-button-wrapper
    border-radius: 50px !important
    width: 50px !important
    height: 50px !important
    border: 1px solid gray_dark !important
    margin-right: 5px
    line-height: 50px !important
    box-sizing: border-box
    text-align: center
    outline: none !important
    &:before
      display: none !important
    &:hover
      // border: 1px solid primary !important
      // background: gray_darker
    &.ant-radio-button-wrapper-checked 
      // border: 1px solid primary !important
      background: gray_base
      color: white
      box-shadow: none !important

.incoming-hour
  composes: db mt4 from __utilities
  color: white
  span
    color: gray_base
    margin-right: 5px

.bookings
  composes: mt5 from __utilities

.moving-table-button-wrapper
  composes: mt5 mb4 tr from __utilities


.moving-table-wrapper
  composes: tc flex justify-center items-center from __utilities
  color: white
  height: calc(100vh - 110px)