@import '../../style/settings/vars'

.main-wrapper 
  min-height: 100vh !important
  :global .ant-page-header-content
    padding-top: 0

.logout-button
  composes: absolute right-0 top-0 bottom-0 flex items-center justify-center from __utilities
  font-size: 1.8rem
  background-color: black
  color: primary
  height 60px
  width: 60px

.page-header
  composes: bb fr justify from __utilities
  border-color: black
  padding: 0 !important
  :global .ant-menu .ant-menu-item
    height: 60px
    line-height: 60px
    :global .anticon
      vertical-align: middle
  :global .ant-menu-horizontal
    border-bottom: 0 !important

.header-date-time
  composes: flex justify-end items-center fw4 f6 pr5 mr2 from __utilities
  height 60px
  span
    color: gray_base

.date-time-wrapper
  composes: ph3 from __utilities
  border-right: 1px solid gray_lighter
  &:last-of-type
    border-right: none

.menu-item-text
  composes: dib pl2 f5 from __utilities
  vertical-align: middle