@import '../../style/settings/vars'

.wrapper
  :global .ant-select-selection-selected-value
    width: 100%
    text-align: left
  
  :global .ant-select-dropdown-menu-item
    background-color: gray_dark
    color: red


.moving-msg-big
  composes: f3 fw3 from __utilities
  color: gray_base

.moving-msg
  composes: f4 fw3 from __utilities

.moving-msg-buttons
  composes: flex justify-center mt4 from __utilities
  & > *
    margin: 0 15px

.select-wrapper
  composes: mt4 mb2 w-100 from __utilities
  
.select
  composes: w-100 from __utilities

.status-icon
  composes: dib br-pill mr2 from __utilities
  width: 10px
  height: 10px

.status-icon-busy
  composes: status-icon
  background-color: yellow

.status-icon-used
  composes: status-icon
  background-color: red

.status-icon-free
  composes: status-icon
  border: 1px solid gray_base

.used-seats
  composes: dib ml2 from __utilities
  &:before
    content: '|'
    color: gray_base

.option-content
  composes: flex from __utilities

.option-content-name
  composes: w40 pr3 from __utilities

.option-content-status
  composes: w60 from __utilities