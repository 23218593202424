@import '../../style/settings/vars'

.row
  composes: h-100 from __utilities
  height: 100% !important

.col
  composes: flex items-center justify-center from __utilities
  height: 100% !important
  min-height: 100vh !important
  display: flex !important
  svg
    max-width: 70%

.code-wrapper
  composes: db tc from __utilities
  color: gray_base
  max-width: 300px

.code-placeholder
  composes: flex items-center justify-center pv4 ph3 from __utilities

.code-placeholder-error
  composes: code-placeholder
  animation: error .075s infinite alternate linear

.code-placeholder-item
  composes: br3 w1 h1 mh2 from __utilities
  border: 1px solid gray_base

.code-placeholder-item-filled
  composes: code-placeholder-item
  background-color: gray_base

.keyboard
  composes: flex items-center justify-center center from __utilities
  flex-wrap: wrap
  max-width: 250px

.keyboard-item
  composes: flex items-center justify-center pv2 w-33 from __utilities
  button
    width: 60px !important
    height: 60px !important

.button-clear
  composes: db from __utilities

@keyframes error
  from
    transform: translateX(-5px)
  to
    transform: translateX(5px)