@import '../settings/vars'

.u-bg-dark
  background-color: primary_dark

.u-color-white
  color: white

.u-bg-gray-light
  background-color: gray_light

.u-hover-primary:hover
  color: primary
