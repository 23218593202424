@import '../settings/vars'

html,
body
  background-color: white
  color: black
  font-size: 15px
  height: 100%

html *
  box-sizing: border-box

blockquote
  color: gray_base
  font-size: 120%
  margin: 1.5rem 0 1.5rem 2rem
