@import '../../style/settings/vars'


.wrapper
  composes: h-100 relative from __utilities
  
.inner
  composes: pa4 h-100 relative from __utilities
  
.title
  composes: mb3 f5 from __utilities
  color: gray_base

.radio-item
  composes: f3 from __utilities
  padding: 10px !important
  display: block !important
  border-top: 1px solid gray_lighter

.number-radio
  :global .ant-radio-button-wrapper
    border-radius: 50px !important
    width: 50px !important
    height: 50px !important
    // border: 1px solid gray_lighter !important
    margin-right: 5px
    line-height: 50px !important
    box-sizing: border-box
    text-align: center
    &:before
      display: none !important
    &.ant-radio-button-wrapper-checked 
      // border: 1px solid primary !important

.incoming-hour
  composes: db mt4 from __utilities
  span
    color: gray_base
    margin-right: 5px

.button-placeholder
  composes: mb3 from __utilities

.plus
  composes: mr2 v-mid from __utilities
