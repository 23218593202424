@import '../../style/settings/vars'

.content
  padding: 30px

.main-wrapper
  min-height: 100vh !important


.content-white
  background: white
  padding: 30px

.tabs
  :global .ant-tabs-top-bar
    background: #1C1E20
    margin-bottom: 0!important
  
  :global .ant-tabs-nav-wrap
    padding-left: 10px

.badge
  composes: dib mr2 br4 from __utilities
  width: 10px
  height 10px
  background-color: blue