// @import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i')
@import './elements'

:global body
  ::selection
    background-color: rgba(lighten(primary, 25%), .5)
    color: black
  #root
    height: 100%

:global .ant-radio-button-wrapper-checked
  background-color: gray_base
  border: 0 !important
  &:first-child
    border: 0 !important

:global .ant-message-notice
  .ant-message-notice-content
    background-color: lighten(gray_dark, 15%) !important

:global .ant-notification-notice
    background-color: lighten(gray_dark, 15%) !important

:global .ant-drawer-close
  color: white
  &:hover
    color: gray_base

:global .ant-select-selection
  background-color: gray_dark
  border-color: gray_dark
  :global .ant-select-selection__placeholder
    color: #666

:global .ant-select-selection--multiple
  :global .ant-select-selection__choice
    background-color: lighten(gray_dark, 10%)
    border-color: gray_dark
    :global .ant-select-selection__choice__remove
      color: white
      &:hover
        color: primary

:global .ant-select-dropdown--multiple
  background-color: gray_dark
  :global .ant-select-dropdown-menu-item-selected
    background-color: lighten(gray_dark, 10%)
    :global .ant-select-selected-icon
      color: white !important

  :global .ant-select-dropdown-menu-item:hover
      background-color: lighten(gray_dark, 15%) !important 
