@import '../settings/vars'

h1, h2, h3, h4, h5, h6
  line-height: 1.2em

p
  line-height: 1.4em
  margin: 0
  margin-bottom: 1rem

a
  text-decoration: none
  color: inherit
