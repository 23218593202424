@import '../../style/settings/vars'

.radio-item
  composes: f3 from __utilities
  padding: 1rem !important
  display: block !important
  border-bottom: 1px solid gray_lighter

.number-radio
  :global .ant-radio-button-wrapper
    border-radius: 50px !important
    width: 50px !important
    height: 50px !important
    border: 1px solid gray_dark !important
    margin-right: 5px
    line-height: 50px !important
    box-sizing: border-box
    text-align: center
    outline: none !important
    &:before
      display: none !important
    &.ant-radio-button-wrapper-checked 
      // border: 1px solid primary !important
      // background: gray_darker
      color: white
      box-shadow: none !important
