@import '../../style/settings/vars'

.main-wrapper
  height: auto
  position: relative

.main-wrapper-no-bg
  composes: main-wrapper
  min-height: 100vh !important

.bg-image
  composes: w-100 db from __utilities