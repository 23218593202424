@import '../../style/settings/vars'



.wrapper
  composes: h-100 relative from __utilities
  color: white
  
.inner
  composes: ph3 h-100 relative from __utilities
  
.title
  composes: mb3 f5 from __utilities
  color: gray_base

.row
  composes: relative pa4 from __utilities
  height: calc(100vh - 110px)
  padding-bottom: 180px

.row-black
  composes: row
  background-color: near_black

.row-header
  composes: bb mb3 flex justify-between from __utilities
  border-color: black

.item
  composes: mb1 f4 from __utilities
  :global .ant-checkbox-wrapper
    font-size: 16px

.selected-list
  composes: list pa0 from __utilities 

.checkbox-item-content
  composes: w-100 flex justify-between from __utilities

.selected-item
  composes: mb1 f4 db from __utilities
  font-size: 16px

.selected-item-button
    composes: checkbox-item-content

.item-price
  color: gray_base

.col-footer
  composes: absolute bottom-0 left-0 right-0 from __utilities
  background-color: gray_darkest

.col-footer-top
  composes: flex bt ph4 justify-between items-center from __utilities
  border-color: black
  height: 70px

.col-footer-top-pay
  composes: col-footer-top
  background-color: black

.col-footer-total
  composes: f5 from __utilities

.col-footer-total-label
  color: gray_base
  
.col-footer-total
  color: white

.productsWrapper
  composes pb3 from __utilities
  max-height: 100%
  overflow-y: auto
  overflow-x: hidden

.checkboxes-wrapper
  :global
    .ant-checkbox-group
      width: 100%
    .ant-checkbox-wrapper
      display: flex
      width: 100%

      span
        display: inline-block
        flex-grow: 1
        &.ant-checkbox
          flex-grow: 0
          display: flex;
          align-items: center;

.checkboxes-wrapper-paid
  composes: checkboxes-wrapper
  composes bt mt3 pv3 o-50 from __utilities
  pointer-events: none
  border-color: black
  :global .ant-checkbox-disabled .ant-checkbox-inner
    background-color: rgba(primary, 0.7)
    border-color: rgba(primary, 0.7) !important
    &:after
      border-color: rgba(white, 0.5) !important

.pay-total
  composes: f4 from __utilities

.divide-by-buttons
  :global button.ant-btn
    margin: 0 2px
    height: 50px !important
    width: 50px !important
    border: 0