@import '../../style/settings/vars'

.wrapper
  composes: dib flex justify-between from __utilities
  color: gray_base
  button
    margin-left: 1rem
  :global .ant-form-item
    margin-bottom: 0
    input
      font-size: 18px
    :global .ant-form-item-control
      line-height: 30px
    :global .ant-form-explain
      position: absolute

.to-pay-amount
  composes: ml2 from __utilities
  color: white

.form
  composes: flex justify-between from __utilities
