@import '../../style/settings/vars'

.payment-method-list
  composes: list ma0 pa0 flex from __utilities

.payment-method-item
  composes: mh2 from __utilities

.payment-method-button
  composes: ba br2 pv2 ph3 tl flex flex-column justify-between from __utilities
  border-color: gray_base
  height: 80px
  width: 140px
  background-color: black
  align-items: initial
  svg
    align-self: flex-end
  &:active
    margin-top: -1px

  &:disabled
    opacity: 0.5
    cursor: not-allowed
    &:active
      margin-top: 0px


.payment-method-value
  composes: br2 db mt2 w-100 from __utilities

.payment-method-label
  composes: mr3 f5 flex flex-column justify-between from __utilities
  height: 80px
  width: 140px

.payment-list
  composes: w-100 flex flex-wrap pa0 mh0 mv4 list from __utilities

.payment-item
  composes: w-100 flex pv3 bt from __utilities
  border-color: black
  &:last-of-type
    border-bottom: 1px solid black

.payment-item-amout
  composes: w4 from __utilities

.payment-item-method
  color: gray_base

.payment-item-method-name
  composes: dib from __utilities
  color: white