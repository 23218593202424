@import '../../style/settings/vars'

.wrapper
  composes: relative pa3 ba br2 from __utilities
  border-color: black
  background-color: gray_dark
  width: 180px
  height: 110px
  padding-bottom: 40px
  outline: none
  line-height: 1.2
  &:active
    transform: translateY(1px)
  
  :global .ant-drawer-close
    color: white !important

  :global .ant-btn
    &:hover
      border: 0 !important

.wrapper-fill
  composes: wrapper
  background: gray_dark
  color: white

.close-button
  position: absolute !important
  top: 0
  right: 0
  transform: translate(40%, -40%)
  color: white !important

.comment-badge
  composes: ba br-pill flex items-center justify-center f6 from __utilities
  height: 20px
  width: 20px
  border-color: gray_base
  background-color: white
  color: primary

.comment-preview
  composes: f6 i from __utilities
  color: rgba(gray_light, .8)

.footer
  composes: absolute tc items-center justify-center left-0 bottom-0 right-0 flex br--bottom br2 from __utilities
  z-index: 10
  height 40px
  line-height: 40px
  background: lighten(gray_dark, 10%)
  color: gray_base

.counter-button
  composes: h-100 from __utilities
  flex-grow: 3

.counter-value
  flex-grow: 2
  background-color: gray_lighter
  color: gray_dark

.title
  composes: mb3 f5 from __utilities
  color: gray_base

.form-footer
  composes: flex items-center justify-between from __utilities

.button-delete
  composes: underline from __utilities
  color: gray_base

.spacer
  composes: h2 from __utilities