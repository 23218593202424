@import '../settings/vars'

.font-headings
  font-family: font_headings

.font-base
  font-family: font_base

.shadow-custom
  box-shadow: 0 .1rem 1rem rgba(black, .1)

.shadow-custom-hover
  &:hover
    box-shadow: 0 .5rem 1.7rem rgba(black, .1)

.u-transition
  transition: all .5s easing

.u-br-200
  border-radius: 2rem

.u-br-150
  border-radius: 1.5rem

.u-br-100
  border-radius: 1rem

.u-br-50
  border-radius: .5rem

.u-br-0
  border-radius: 0

.u-vertical-centered
  top: 50%
  transform: translateY(-50%)

.u-video-wrapper
  position: relative
  padding: 0
  overflow: hidden
  padding-bottom: 56.25%
  height: 0
  iframe
    position: absolute
    top:0
    left: 0
    width: 100%
    height: 100%

.u-centered
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.u-animated-loading
  position: relative
  transition: .3s color
  transition-delay: .3s
  &:after
    content: ""
    height: 100%
    width: 100%
    background: gray_ultralight
    background: linear-gradient(to right, gray_ultralight 8%, gray_lighter 18%, gray_ultralight 28%)
    background-size: 400% 100%
    animation: loading 2s infinite linear
    opacity: 0
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)
    transition: .3s opacity
  &:empty:after
    opacity: 1
  &:empty
    color: transparent

@keyframes loading
  from
    background-position: 100% 0%
  to
    background-position: -100% 0%

:global
  .text-content
    font-size: 1.15rem
    line-height: 1.6
    color: gray_dark
    .alignleft
      float: left
      margin-top: .5rem
      margin-right: .5rem
      margin-bottom: .5rem
    .alignright
      float: right
      margin-top: .5rem
      margin-left: .5rem
      margin-bottom: .5rem
    img
    .alignright
    .alignleft
      @media screen and (max-width: 580px)
        float: none
        display: block
        width: auto
        height: auto
        margin: 1rem auto
    ul
      margin-top: 1rem
      list-style: none
      padding-left: 0
      li
        position: relative
        margin-bottom: 1rem
        padding-left: 1.75rem
        &:before
          position: absolute
          left: 0
          top: .5rem
          height: .8rem
          width: .8rem
          border-radius: 1rem
          content: ''
          background-color: primary
