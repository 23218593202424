@import '../../style/settings/vars'

.header
  composes: relative flex items-center justify-between bb pr4 from __utilities
  background: gray_darkest
  height: 48px
  border-color: black
  z-index: 1000

.header-left
  composes: flex items-center from __utilities

.title
  composes: f4 fw4 ma0 ph4 h-100 mr3 from __utilities
  color: black
  background-color: yellow
  line-height: 48px
  height: 48px

.status
  composes: f6 from __utilities
  color: gray_base

.status-value
  composes: fw6 from __utilities
  color: white

.header-actions
  justify-self: flex-end
  :global .ant-menu
    background: gray_darkest
    width: 500px
    text-align: right 

.locked-table-wrapper
  composes: tc flex justify-center items-center from __utilities
  color: white
  height: 500px
  height: 100vh
  height: calc(100vh - 120px)

.locked-msg-big
  composes: f3 fw3 from __utilities
  color: gray_base

.locked-msg
  composes: f4 fw3 from __utilities

.locked-msg-buttons
  composes: flex justify-center mt4 from __utilities
  & > *
    margin: 0 15px