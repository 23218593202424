@import '../../style/settings/vars'

.wrapper
  composes: mv4 responsive from __utilities
  color: white

.inputWrapper
  composes: mb3 from __utilities

.form-footer
  composes: flex items-center justify-between from __utilities

.button-delete
  composes: underline from __utilities
  color: gray_base