@import '../../style/settings/vars'

.header
  composes: ph4 f4 bb flex items-center from __utilities
  border-color: black
  color: white
  height 61px

.header-to-pay
  composes: dib from __utilities
  color: gray_base

.wrapper
  composes: mb3 from __utilities
  color: white

.section
  composes: mv3 from __utilities

.section-title
  composes: f4 bb pb2 fw4 ph4 mb4 from __utilities
  border-color: black
  color: gray_base

.section-inner
  composes: ph4 mb5 from __utilities

.bill-number
  composes: f3 mv3 mr4 from __utilities

.footer
  composes: w-100 ph4 flex justify-end from __utilities

.footer-col
  composes: ml3 from __utilities