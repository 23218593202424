@import '../../style/settings/vars'

.wrapper
  composes: mb3 from __utilities
  :global .ant-card-body
    padding-top: 12px
    padding-bottom: 12px
  :global .ant-divider-horizontal
    margin-top: 12px
    margin-bottom: 12px
  :global .ant-card-actions li
    color: white

.course
  composes: flex justify-between items-center from __utilities

.comment
  composes: ml4 mb1 f6 from __utilities
  color: gray_base